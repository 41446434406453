import React, { memo, useMemo, useState, useEffect } from "react";

import Button from "components/Button";
import Link from "components/Link";
import propTypes from "prop-types";
import { Box, Typography, CircularProgress } from "@mui/material";
import style from "components/Investment/style.module.scss";
import Image from "next/image";
import cs from "classnames";
import { KeySVG } from "assets/svg";

import InvestmentsServices from "services/InvestmentsServices";
import DisplayElements from "./components/DisplayElements";
import { markersInvestmets } from "utils/dictionary-markers";
import NoImage from "components/NoImage";
import { getCityAndDistrict } from "utils/functions";

const ImageHorizontalContainer = ({
  uuid,
  investment_uuid,
  baseURL,
  type,
  fileImage,
  marker: markerProp,
  similarType,
  objectInvestment,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [investment_picture, setInvestment_picture] = useState(null);
  const [investmentMarker, setInvestmentMarker] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (markerProp) {
      setMarker(markerProp);
    } else if (investmentMarker) {
      setMarker(investmentMarker);
    }
  }, [markerProp, investmentMarker]);

  useEffect(() => {
    if (investment_uuid && !fileImage) {
      InvestmentsServices.getInvesment(investment_uuid).then((res) => {
        if (res?.status === 200) {
          if (
            res?.data?.data?.investment_pictures &&
            res?.data?.data?.investment_pictures[0]?.file
          ) {
            setInvestment_picture(res?.data?.data.investment_pictures[0].file);
          }
        }
        if (process.env.SECONDARY_MARKET_UUID !== investment_uuid) {
          setInvestmentMarker(getMarker(res?.data?.data));
        }
        setIsLoaded(false);
        setLoaded(false);
        setTimeout(() => {
          setIsLoaded(true);
        }, 0);
      });
    } else {
      setIsLoaded(false);
      setLoaded(false);
      setInvestment_picture(true);
      setTimeout(() => {
        setIsLoaded(true);
      }, 0);
    }
  }, [investment_uuid]);

  const getMarker = (el) => {
    if (el) {
      let returnElement = null;
      markersInvestmets?.forEach((elForEach, index) => {
        if (
          !returnElement &&
          el[elForEach.value] &&
          el[elForEach.value] == "1"
        ) {
          returnElement = elForEach;
        }
      });

      return returnElement;
    }
    return null;
  };
  const getLink = useMemo(() => {
    if (similarType !== "investment") return `/flat/${uuid}`;
    if (objectInvestment?.city) {
      const splitName = objectInvestment?.city?.split(", ");
      const newLink = getCityAndDistrict(splitName[0], splitName[1]);

      return `/investment/${newLink}/${uuid}`;
    } else return `/investment/polska/${uuid}`;
  }, [objectInvestment, similarType]);

  const src =
    fileImage || investment_picture
      ? `${baseURL}${type ? "bigtn_" : "h30_"}${
          fileImage ? fileImage : investment_picture
        }`
      : null;

  if (!isLoaded)
    return (
      <div className={style.image}>
        <Box
          className={style.loadedImagePlaceholder}
          height="165px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </div>
    );
  else {
    return (
      <div className={style.image}>
        {!loaded && src && (
          <Box
            className={style.loadedImagePlaceholder}
            height="165px"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        )}

        {src ? (
          <Link href={getLink}>
            <Box
              width="100%"
              height="165px"
              position="absolute"
              sx={{
                opacity: loaded ? 1 : 0,
              }}
            >
              <Image
                unoptimized
                alt="investment image"
                layout="fill"
                objectFit="cover"
                src={src}
                // quality={75}
                onError={(e) => setInvestment_picture(null)}
                onLoadingComplete={() => setLoaded(true)}
              />
              {marker && (
                <Box
                  position="absolute"
                  display="flex"
                  sx={{
                    opacity: loaded ? 1 : 0,
                    boxShadow: "2px 0 4px 1px rgba(0, 0, 0, 0.1)",
                  }}
                  bgcolor="white"
                  left={0}
                  top={8}
                  padding={2}
                >
                  <Box
                    mr={2}
                    display="flex"
                    justifyItems="center"
                    alignItems="center"
                  >
                    {marker?.icon ? (
                      <Image
                        unoptimized
                        alt={`marker icon`}
                        src={marker?.icon}
                        width={marker.iconWidth}
                        height={marker.iconHeight}
                      />
                    ) : (
                      <KeySVG size={24} />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      fontSize="0.85rem"
                      lineHeight="0.85rem"
                      color="black"
                      fontWeight={800}
                    >
                      {marker?.first_line}
                    </Typography>
                    <Typography
                      fontSize="0.85rem"
                      lineHeight="0.85rem"
                      color="black"
                    >
                      {marker?.second_line}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Link>
        ) : (
          <Link href={getLink}>
            <Box width="100%" position="relative" minHeight={165}>
              <NoImage height={165} />
              {marker && (
                <Box
                  position="absolute"
                  display="flex"
                  sx={{
                    opacity: loaded ? 1 : 0,
                    boxShadow: "2px 0 4px 1px rgba(0, 0, 0, 0.1)",
                  }}
                  bgcolor="white"
                  left={0}
                  top={8}
                  padding={2}
                >
                  <Box
                    mr={2}
                    display="flex"
                    justifyItems="center"
                    alignItems="center"
                  >
                    {marker?.icon ? (
                      <Image
                        unoptimized
                        alt={`marker icon`}
                        src={marker?.icon}
                        width={marker.iconWidth}
                        height={marker.iconHeight}
                      />
                    ) : (
                      <KeySVG size={24} />
                    )}
                  </Box>
                  <Box>
                    <Typography
                      fontSize="0.85rem"
                      lineHeight="0.85rem"
                      color="black"
                      fontWeight={800}
                    >
                      {marker?.first_line}
                    </Typography>
                    <Typography
                      fontSize="0.85rem"
                      lineHeight="0.85rem"
                      color="black"
                    >
                      {marker?.second_line}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>{" "}
          </Link>
        )}
      </div>
    );
  }
};

const Investment = memo(
  ({
    key,
    visible_fields: {
      investment_district,
      investment_uuid,
      uuid,
      area_from,
      area_to,
      rooms_from,
      rooms_to,
      city,
      price,
      files,
      name,
      description,
      marker,
      price_to,
      price_from,
    },
    backgroundColor = "grey.100",
    lastOfferPrimaryMarket = false,
    lastOfferSecondaryMarket = false,
    similars = false,
    similarType = null,
    scrollToContact,
  }) => {
    const objectInvestment = {
      investment_district,
      city,
    };
    const objectProps = {
      visible_fields: {
        investment_district,
        investment_uuid,
        uuid,
        area_from,
        area_to,
        rooms_from,
        rooms_to,
        city,
        price,
        files,
        name,
        description,
        price_to,
        price_from,
      },
      backgroundColor: "grey.100",
      lastOfferPrimaryMarket: lastOfferPrimaryMarket,
      lastOfferSecondaryMarket: lastOfferSecondaryMarket,
      similars: similars,
      similarType: similarType,
    };
    const baseURL = !lastOfferSecondaryMarket
      ? process.env.BASE_IMG_URL
      : process.env.BASE_PLAN_URL;
    const type = lastOfferSecondaryMarket;
    const fileImage = type && files?.length > 0 && files?.[0].file;

    return (
      <Box
        className={style.container}
        position="relative"
        display={"flex"}
        flexDirection="column"
        justifyContent="flex-start"
        alignItems={"stretch"}
        height="100%"
        bgcolor={backgroundColor}
        maxWidth={282.5}
      >
        <ImageHorizontalContainer
          uuid={uuid}
          investment_uuid={investment_uuid}
          baseURL={baseURL}
          type={type}
          fileImage={fileImage}
          marker={marker}
          similarType={similarType}
          objectInvestment={objectInvestment}
        />

        <DisplayElements
          props={objectProps}
          scrollToContact={scrollToContact}
        />

        {!similars && (
          <Link href={`/flat/${uuid}`}>
            <Box width="100%" height={"100%"} p={2}>
              <Button color="secondary" style={{ height: "40px" }}>
                Sprawdź
              </Button>
            </Box>
          </Link>
        )}
      </Box>
    );
  }
);

Investment.propTypes = {
  name: propTypes.string,
  address: propTypes.string,
  rooms_from: propTypes.number,
  rooms_to: propTypes.number,
  meters_from: propTypes.number,
  meters_to: propTypes.number,
  image: propTypes.string,
  pricemkw: propTypes.number,
  visible_fields: propTypes.object,
};
Investment.defaultProps = {
  visible_fields: {
    pricemkw: true,
    meters_from: true,
    rooms_from: true,
    rooms_to: true,
  },
};

export default Investment;
