import { Box, Typography } from "@mui/material";
import theme from "theme";

const NoImage = ({
  height = 280,
  size = 100,
  className,
  noPaddingTop = false,
}) => {
  return (
    <Box
      component="span"
      minHeight={height}
      height="100%"
      width="100%"
      backgroundColor="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      position="static"
      sx={{ opacity: "100 !important", ...className }}
    >
      <Box
        component="span"
        border={`6px solid ${theme.palette.grey[300]}`}
        borderRadius="50%"
        height={size}
        width={size}
        minHeight={size}
        display="flex"
        justifyContent="center"
        sx={{ opacity: "100 !important", position: "relative !important" }}
      >
        <Box
          component="span"
          height="106%"
          width={6}
          backgroundColor={theme.palette.grey[300]}
          position="absolute"
          top={-3}
          sx={{
            transform: "rotate(45deg)",
          }}
        />
      </Box>
      <Typography
        pt={noPaddingTop ? 0 : 4}
        color={theme.palette.grey[300]}
        variant="p"
        fontWeight="800"
        fontSize="1.5rem"
      >
        NO IMAGE
      </Typography>
    </Box>
  );
};

export default NoImage;
