import React, { memo, useState, useEffect } from "react";
import Image from "next/image";
import propTypes from "prop-types";
import { Box, Typography } from "@mui/material";

import style from "components/Investment/style.module.scss";

import zlIcon from "assets/icons/zl.png";
import m2Icon from "assets/icons/m2.png";
import krzesloIcon from "assets/icons/krzeslo.png";
import domIcon from "assets/icons/dom.png";
import { handleNamesRooms, regexStringToNumberPrice } from "utils/functions";

import InvestmentsServices from "services/InvestmentsServices";

function createMarkup(text) {
  // let editText = text.slice(0, 200);
  // if (text.length > 100) editText = editText + "...";

  return { __html: `${text}` };
}

const LastOfferPrimary = ({ data: { rooms_from, area_from } }) => {
  return (
    <Box>
      <Typography color="black" fontSize="0.85rem">
        Pozostało dostępnych lokali: <strong>10</strong>
      </Typography>
      <Box mt={3}>
        <ul className={style.list} style={{ marginTop: 0 }}>
          {rooms_from && (
            <li>
              <Box display="flex" alignItems="center">
                <Box display="flex" justifyItems="center" alignItems="center">
                  <Image
                    unoptimized
                    alt={`krzesło icon`}
                    src={krzesloIcon}
                    width={26}
                    height={24}
                  />
                </Box>
                <Box ml={2}>
                  <Typography color="black" fontSize="0.85rem">
                    od:{" "}
                    <Typography component="span" color="black" fontWeight={800}>
                      {rooms_from}
                    </Typography>{" "}
                    do:{" "}
                    <Typography component="span" color="black" fontWeight={800}>
                      {rooms_from}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </li>
          )}
          {area_from && (
            <li>
              <Box display="flex" alignItems="center">
                <Box display="flex" justifyItems="center" alignItems="center">
                  <Image
                    unoptimized
                    alt={`dom icon`}
                    src={domIcon}
                    width={26}
                    height={24}
                  />
                </Box>
                <Box ml={2}>
                  <Typography color="black" fontSize="0.85rem">
                    od:{" "}
                    <Typography component="span" color="black" fontWeight={800}>
                      {area_from}
                    </Typography>{" "}
                    m<sup>2</sup> do:{" "}
                    <Typography component="span" color="black" fontWeight={800}>
                      {area_from}
                    </Typography>{" "}
                    m<sup>2</sup>
                  </Typography>
                </Box>
              </Box>
            </li>
          )}
        </ul>
      </Box>
    </Box>
  );
};
const LastOfferSecondary = ({ data: { rooms_from, area_from } }) => {
  return (
    <Box mt={3} width="100%" display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" flexBasis="50%">
        <Box display="flex" justifyItems="center" alignItems="center">
          <Image
            unoptimized
            alt={`krzesło icon`}
            src={krzesloIcon}
            width={32}
            height={25}
          />
        </Box>
        <Box ml={2}>
          <Typography variant="body2" color="black">
            <Typography component="span" color="black" fontWeight={800}>
              {rooms_from}
            </Typography>{" "}
            {handleNamesRooms(rooms_from)}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" flexBasis="50%">
        <Box display="flex" justifyItems="center" alignItems="center" mb={0.5}>
          <Image
            unoptimized
            alt={`dom icon`}
            src={domIcon}
            width={26}
            height={26}
          />
        </Box>
        <Box ml={2}>
          <Typography variant="body2" color="black">
            <Typography component="span" color="black" fontWeight={800}>
              {area_from}
            </Typography>{" "}
            m<sup>2</sup>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const Similars = ({
  data: {
    price_from,
    price_to,
    area_from,
    area_to,
    rooms_from,
    rooms_to,
    investment_uuid,
  },
  scrollToContact,
}) => {
  const secondaryMarket = investment_uuid === process.env.SECONDARY_MARKET_UUID;

  return (
    <Box>
      <Box mt={3}>
        <ul className={style.list} style={{ marginTop: 0 }}>
          <li>
            <Box display="flex" alignItems="center">
              <Box display="flex" justifyItems="center" alignItems="center">
                <Image alt={`zl icon`} src={zlIcon} width={24} height={24} />
              </Box>
              <Box ml={2}>
                {price_from ? (
                  <Typography color="black" fontSize="0.85rem">
                    {!secondaryMarket && "od: "}
                    {regexStringToNumberPrice(price_from)} PLN
                  </Typography>
                ) : (
                  <Typography
                    onClick={() => scrollToContact()}
                    color="secondary"
                    fontSize="0.85rem"
                    sx={{ cursor: "pointer" }}
                  >
                    Zapytaj eksperta
                  </Typography>
                )}
              </Box>
            </Box>
          </li>
          {!secondaryMarket && (
            <li>
              <Box display="flex" alignItems="center">
                <Box display="flex" justifyItems="center" alignItems="center">
                  <Image alt={`zl icon`} src={zlIcon} width={24} height={24} />
                </Box>
                <Box ml={2}>
                  {price_from ? (
                    <Typography color="black" fontSize="0.85rem">
                      do: {regexStringToNumberPrice(price_to)} PLN
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() => scrollToContact()}
                      color="secondary"
                      fontSize="0.85rem"
                      sx={{ cursor: "pointer" }}
                    >
                      Zapytaj eksperta
                    </Typography>
                  )}
                </Box>
              </Box>
            </li>
          )}
          <li>
            <Box display="flex" alignItems="center">
              <Box display="flex" justifyItems="center" alignItems="center">
                <Image alt={`m2 icon`} src={m2Icon} width={24} height={24} />
              </Box>
              <Box ml={2}>
                <Typography color="black" fontSize="0.85rem">
                  od: {area_from}m<sup>2</sup>
                  {!secondaryMarket && (
                    <span>
                      {" "}
                      do: {area_to ? area_to : area_from}m<sup>2</sup>
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
          </li>

          <li>
            <Box display="flex" alignItems="center">
              <Box display="flex" justifyItems="center" alignItems="center">
                <Image
                  alt={`krzesło icon`}
                  src={krzesloIcon}
                  width={24}
                  height={24}
                />
              </Box>
              <Box ml={2}>
                <Typography color="black" fontSize="0.85rem">
                  od: {rooms_from} do: {rooms_to ? rooms_to : rooms_from}
                </Typography>
              </Box>
            </Box>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

const SimilarsFlat = ({
  data: { price, area_from, rooms_from, investment_uuid },
  scrollToContact,
}) => {
  const secondaryMarket = investment_uuid === process.env.SECONDARY_MARKET_UUID;

  return (
    <Box>
      <Box mt={3}>
        <ul className={style.list} style={{ marginTop: 0 }}>
          <li>
            <Box display="flex" alignItems="center">
              <Box display="flex" justifyItems="center" alignItems="center">
                <Image alt={`zl icon`} src={zlIcon} width={24} height={24} />
              </Box>
              <Box ml={2}>
                {price ? (
                  <Typography color="black" fontSize="0.85rem">
                    {!secondaryMarket && "od: "}
                    {regexStringToNumberPrice(price)} PLN
                  </Typography>
                ) : (
                  <Typography
                    onClick={() => scrollToContact()}
                    color="secondary"
                    fontSize="0.85rem"
                    sx={{ cursor: "pointer" }}
                  >
                    Zapytaj eksperta
                  </Typography>
                )}
              </Box>
            </Box>
          </li>

          <li>
            <Box display="flex" alignItems="center">
              <Box display="flex" justifyItems="center" alignItems="center">
                <Image alt={`m2 icon`} src={m2Icon} width={24} height={24} />
              </Box>
              <Box ml={2}>
                <Typography color="black" fontSize="0.85rem">
                  {area_from}m<sup>2</sup>
                </Typography>
              </Box>
            </Box>
          </li>

          <li>
            <Box display="flex" alignItems="center">
              <Box display="flex" justifyItems="center" alignItems="center">
                <Image
                  alt={`krzesło icon`}
                  src={krzesloIcon}
                  width={24}
                  height={24}
                />
              </Box>
              <Box ml={2}>
                <Typography color="black" fontSize="0.85rem">
                  {rooms_from} {handleNamesRooms(rooms_from)}
                </Typography>
              </Box>
            </Box>
          </li>
        </ul>
      </Box>
    </Box>
  );
};
const DefaultElement = ({ data: { description }, scrollToContact }) => {
  return (
    <Box>
      <Typography variant="body2" color="black" fontWeight={600}>
        Expert Adam Kowalski
      </Typography>
      <Box mt={3}>
        {description ? (
          <Typography
            style={{ color: "black" }}
            lineHeight="1.25rem"
            textOverflow="ellipsis"
            maxHeight="160px"
            minHeight="160px"
            overflow="hidden"
            dangerouslySetInnerHTML={createMarkup(description)}
          ></Typography>
        ) : (
          <Typography
            textOverflow="ellipsis"
            maxHeight="160px"
            minHeight="160px"
            overflow="hidden"
            style={{ color: "black" }}
            lineHeight="1.25rem"
          >
            Wyjątkowo luksusowy i nowoczesny apartamentowiec stworzony dla
            najbardziej wymagających klientów. Najwyższy standard apartamentowca
            mieszkalnego w Szczecinie na miarę 5-gwiazdkowego hotelu.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const DisplayElements = memo(
  ({
    props: {
      key,
      visible_fields: {
        investment_district,
        investment_uuid,
        uuid,
        area_from,
        area_to,
        rooms_from,
        rooms_to,
        city,
        price,
        price_from,
        price_to,
        name,
        files,
        description,
      },

      lastOfferPrimaryMarket = false,
      lastOfferSecondaryMarket = false,
      similars = false,
      similarType = null,
    },
    scrollToContact,
  }) => {
    const [investmentData, setInvestmentData] = useState(null);

    let type = "";
    // if (lastOfferPrimaryMarket) type = "lastOfferPrimary";
    if (lastOfferPrimaryMarket) type = "lastOfferSecondary";
    if (lastOfferSecondaryMarket) type = "lastOfferSecondary";
    if (similars) type = "similars";
    const SwitchComponent = (type, data, similarType, scrollToContact) => {
      switch (type) {
        case "lastOfferPrimary":
          return (
            <LastOfferPrimary data={data} scrollToContact={scrollToContact} />
          );
        case "lastOfferSecondary":
          return (
            <LastOfferSecondary data={data} scrollToContact={scrollToContact} />
          );
        case "similars":
          // return <SimilarsFlat data={data} />;
          if (similarType === "flat") {
            return (
              <SimilarsFlat data={data} scrollToContact={scrollToContact} />
            );
          } else if (similarType === "investment") {
            return <Similars data={data} scrollToContact={scrollToContact} />;
          }
        default:
          return (
            <DefaultElement data={data} scrollToContact={scrollToContact} />
          );
      }
    };

    useEffect(() => {
      if (investment_uuid) {
        InvestmentsServices.getInvesment(investment_uuid).then((res) => {
          if (res?.status === 200) {
            setInvestmentData(res?.data?.data);
          }
        });
      }
    }, [investment_uuid]);

    return (
      <Box
        paddingX={similars ? 0 : 5}
        pt={4}
        pb={2}
        width="100%"
        flexGrow={1}
        className={style.textContent}
      >
        {!similars && (
          <Typography>
            <Box component="span" fontSize={"0.85rem"} color="black">
              Rynek:
              <Typography
                component="span"
                variant="body2"
                fontWeight={800}
                color="secondary"
                fontSize="1em"
              >
                {" "}
                {lastOfferSecondaryMarket ? "Wtórny" : "Pierwotny"}
              </Typography>
            </Box>
          </Typography>
        )}
        <Box mt={2}>
          <Typography
            variant="p"
            color={"primary"}
            fontSize="1.25rem"
            fontWeight={800}
          >
            {!similars
              ? `${
                  lastOfferSecondaryMarket
                    ? city
                    : investmentData?.city
                    ? `${investmentData?.city}`
                    : ""
                }${
                  investmentData?.district
                    ? `, ${investmentData?.district}`
                    : ""
                } `
              : `${city}`}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection="row"
          justifyContent="space-between"
        >
          {/* -- change to switch case -- */}
          {SwitchComponent(
            type,
            {
              investment_district,
              investment_uuid,
              uuid,
              area_from,
              area_to,
              rooms_from,
              rooms_to,
              city,
              price,
              price_from,
              price_to,
              files,
              description,
            },
            similarType,
            scrollToContact
          )}
        </Box>
      </Box>
    );
  }
);

export default DisplayElements;
